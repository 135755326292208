var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { Typography, Stack, Card, Box, Divider, Switch, FormControl, FormLabel, Input, Button, FormHelperText, } from "@mui/joy";
import AddIcon from "@mui/icons-material/Add";
import Variables from "./components/Variables";
import Action from "./components/Action";
export default function DataCollection(props) {
    var plugins = props.plugins, invalidFields = props.invalidFields, onNewPlugin = props.onNewPlugin, onPluginNameChange = props.onPluginNameChange, onPluginIsEnabledChange = props.onPluginIsEnabledChange, onPluginVariablePropertyChange = props.onPluginVariablePropertyChange, onReorderVariables = props.onReorderVariables, onPluginNewVariable = props.onPluginNewVariable, onPluginDeleteVariable = props.onPluginDeleteVariable, onPluginActionTypeChange = props.onPluginActionTypeChange, onPluginActionDescriptionChange = props.onPluginActionDescriptionChange, onPluginExecuteAfterChange = props.onPluginExecuteAfterChange, onPluginInjectResultChange = props.onPluginInjectResultChange, onPluginActionArgsPropertyChange = props.onPluginActionArgsPropertyChange, onPluginActionArgsNewHeader = props.onPluginActionArgsNewHeader, onPluginActionArgsUpdateHeader = props.onPluginActionArgsUpdateHeader, onPluginActionArgsDeleteHeader = props.onPluginActionArgsDeleteHeader, onPluginVariableAddEnumValue = props.onPluginVariableAddEnumValue, onPluginVariableUpdateEnumValue = props.onPluginVariableUpdateEnumValue, onPluginVariableDeleteEnumValue = props.onPluginVariableDeleteEnumValue, onPluginActionArgsDeleteAdditionalBodyVariable = props.onPluginActionArgsDeleteAdditionalBodyVariable, onPluginActionArgsNewAdditionalBodyVariable = props.onPluginActionArgsNewAdditionalBodyVariable, onPluginActionArgsUpdateAdditionalBodyVariable = props.onPluginActionArgsUpdateAdditionalBodyVariable, 
    //VariableAction
    onVariableActionEnabled = props.onVariableActionEnabled, onVariableActionTypeChange = props.onVariableActionTypeChange, onVariableActionDescriptionChange = props.onVariableActionDescriptionChange, onVariableActionArgsPropertyChange = props.onVariableActionArgsPropertyChange, onVariableActionArgsNewHeader = props.onVariableActionArgsNewHeader, onVariableActionArgsUpdateHeader = props.onVariableActionArgsUpdateHeader, onVariableActionArgsDeleteHeader = props.onVariableActionArgsDeleteHeader, onVariableActionArgsDeleteAdditionalBodyVariable = props.onVariableActionArgsDeleteAdditionalBodyVariable, onVariableActionArgsNewAdditionalBodyVariable = props.onVariableActionArgsNewAdditionalBodyVariable, onVariableActionArgsUpdateAdditionalBodyVariable = props.onVariableActionArgsUpdateAdditionalBodyVariable;
    return (_jsxs(_Fragment, { children: [_jsxs(Stack, { children: [_jsx(Typography, __assign({ level: "title-md" }, { children: "Data Collection" })), _jsx(Typography, __assign({ level: "body-sm" }, { children: "Use Data Collection plugin to gather information from users and send it to an HTTP endpoint." }))] }), plugins
                .filter(function (p) { return p.pluginType === "DataCollectionPlugin"; })
                .map(function (plugin, pluginIndex) { return (_jsx(React.Fragment, { children: _jsxs(Card, { children: [_jsxs(Box, __assign({ sx: {
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            } }, { children: [_jsx(Stack, __assign({ spacing: 1, sx: {
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "flex-start",
                                    } }, { children: _jsx(Box, __assign({ sx: {
                                            display: "flex",
                                            alignItems: "center",
                                            maxWidth: "500px",
                                        } }, { children: _jsx(Typography, __assign({ level: "title-md", noWrap: true }, { children: plugin.pluginName })) })) })), _jsx(Switch, { checked: plugin.isEnabled, onChange: function (event) {
                                        return onPluginIsEnabledChange(pluginIndex, event.target.checked);
                                    }, size: "lg", variant: "outlined" })] })), plugin.isEnabled && (_jsxs(_Fragment, { children: [_jsx(Divider, {}), _jsxs(Stack, __assign({ spacing: 3 }, { children: [_jsx(Stack, __assign({ spacing: 2, sx: { my: 1 } }, { children: _jsxs(Stack, __assign({ spacing: 1 }, { children: [_jsx(FormLabel, { children: "Plugin Name" }), _jsxs(FormControl, __assign({ sx: {
                                                            display: { sm: "flex-column", md: "flex-row" },
                                                        }, error: invalidFields.some(function (e) { return e.field === "plugins[".concat(pluginIndex, "].name"); }) }, { children: [_jsx(Input, { size: "sm", placeholder: "Plugin Name", value: plugin.pluginName, onChange: function (event) {
                                                                    return onPluginNameChange(pluginIndex, event);
                                                                } }), invalidFields
                                                                .filter(function (e) {
                                                                return e.field === "plugins[".concat(pluginIndex, "].name") &&
                                                                    e.error !== "";
                                                            })
                                                                .map(function (e, index) { return (_jsx(FormHelperText, { children: e.error }, index)); })] }))] })) })), _jsx(Variables, { variables: plugin.pluginVariables, pluginIndex: pluginIndex, invalidFields: invalidFields, onPluginVariablePropertyChange: onPluginVariablePropertyChange, onPluginNewVariable: onPluginNewVariable, onPluginDeleteVariable: onPluginDeleteVariable, onPluginVariableAddEnumValue: onPluginVariableAddEnumValue, onPluginVariableUpdateEnumValue: onPluginVariableUpdateEnumValue, onPluginVariableDeleteEnumValue: onPluginVariableDeleteEnumValue, onReorderVariables: onReorderVariables, 
                                            //VariableAction
                                            onVariableActionEnabled: onVariableActionEnabled, onVariableActionTypeChange: onVariableActionTypeChange, onVariableActionDescriptionChange: onVariableActionDescriptionChange, onVariableActionArgsPropertyChange: onVariableActionArgsPropertyChange, onVariableActionArgsNewHeader: onVariableActionArgsNewHeader, onVariableActionArgsUpdateHeader: onVariableActionArgsUpdateHeader, onVariableActionArgsDeleteHeader: onVariableActionArgsDeleteHeader, onVariableActionArgsDeleteAdditionalBodyVariable: onVariableActionArgsDeleteAdditionalBodyVariable, onVariableActionArgsNewAdditionalBodyVariable: onVariableActionArgsNewAdditionalBodyVariable, onVariableActionArgsUpdateAdditionalBodyVariable: onVariableActionArgsUpdateAdditionalBodyVariable }), _jsx(Action, { action: plugin.pluginAction, pluginIndex: pluginIndex, onPluginActionArgsPropertyChange: onPluginActionArgsPropertyChange, onPluginActionTypeChange: onPluginActionTypeChange, onPluginActionDescriptionChange: onPluginActionDescriptionChange, onPluginExecuteAfterChange: onPluginExecuteAfterChange, onPluginInjectResultChange: onPluginInjectResultChange, onPluginActionArgsNewHeader: onPluginActionArgsNewHeader, onPluginActionArgsUpdateHeader: onPluginActionArgsUpdateHeader, onPluginActionArgsDeleteHeader: onPluginActionArgsDeleteHeader, onPluginActionArgsDeleteAdditionalBodyVariable: onPluginActionArgsDeleteAdditionalBodyVariable, onPluginActionArgsNewAdditionalBodyVariable: onPluginActionArgsNewAdditionalBodyVariable, onPluginActionArgsUpdateAdditionalBodyVariable: onPluginActionArgsUpdateAdditionalBodyVariable })] }))] }))] }) }, plugin.id)); }), _jsx(Stack, { children: _jsx(Button, __assign({ color: "primary", variant: "soft", startDecorator: _jsx(AddIcon, {}), sx: { alignSelf: "flex-start" }, onClick: onNewPlugin }, { children: "Add new Data Collection" })) })] }));
}
