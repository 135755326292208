var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Box, Card, CardContent, Typography, Select, Stack, Input, FormLabel, } from "@mui/joy";
import { Chart, Line } from "react-chartjs-2";
import { Chart as ChartJS, Tooltip, Legend, LinearScale, CategoryScale, BarElement, PointElement, LineElement, } from "chart.js";
import Skeleton from "react-loading-skeleton";
import Option from "@mui/joy/Option";
import { FilterEnums } from "../../types/enums";
import { useCallAnalytics } from "../../data/hooks/useCallAnalytics";
import { FunnelController, TrapezoidElement, } from "chartjs-chart-funnel";
ChartJS.register(Tooltip, Legend, LinearScale, CategoryScale, BarElement, PointElement, LineElement);
ChartJS.register(FunnelController, TrapezoidElement, LinearScale, CategoryScale);
export var CallAnalytics = function () {
    var _a = useCallAnalytics(), trendingChartData = _a.trendingChartData, isLoading = _a.isLoading, getTrendingChartData = _a.getTrendingChartData, funnelChartData = _a.funnelChartData, isLoadingFunnel = _a.isLoadingFunnel, getFunnelChartData = _a.getFunnelChartData, flows = _a.flows, getFlows = _a.getFlows;
    var defaultDateRange = FilterEnums.last12Hours;
    var defaultGrouping = "hourly";
    var _b = useState(defaultDateRange), dateRange = _b[0], setDateRange = _b[1];
    var _c = useState(defaultGrouping), grouping = _c[0], setGrouping = _c[1];
    var _d = useState(undefined), selectedFlow = _d[0], setSelectedFlow = _d[1];
    var _e = useState(15), conversionCriteriaInSeconds = _e[0], setConversionCriteriaInSeconds = _e[1];
    // Fetch initial data and flows
    useEffect(function () {
        getFlows();
    }, []);
    // Fetch trending data
    useEffect(function () {
        getTrendingChartData(dateRange, grouping, selectedFlow);
    }, [dateRange, grouping, selectedFlow]);
    // -- FETCH FUNNEL DATA (the second chart) --
    useEffect(function () {
        getFunnelChartData(dateRange, grouping, conversionCriteriaInSeconds, selectedFlow);
    }, [dateRange, grouping, conversionCriteriaInSeconds, selectedFlow]);
    /**
     * Convert each UTC label to the user's local time, formatted
     * according to the grouping.
     */
    var adjustLabelsToLocalTime = function (labels) {
        return labels.map(function (label) {
            var date = new Date(label);
            var formattedDate;
            switch (grouping) {
                case "hourly":
                    formattedDate = date.toLocaleString(undefined, {
                        month: "short",
                        day: "numeric",
                        hour: "numeric",
                        hour12: true,
                    });
                    break;
                case "daily":
                    formattedDate = date.toLocaleString(undefined, {
                        month: "short",
                        day: "numeric",
                    });
                    break;
                case "monthly":
                    formattedDate = date.toLocaleString(undefined, {
                        month: "short",
                        year: "numeric",
                    });
                    break;
                default:
                    formattedDate = date.toLocaleString(undefined, {
                        dateStyle: "short",
                        timeStyle: "short",
                    });
            }
            return formattedDate;
        });
    };
    return (_jsxs(Stack, __assign({ direction: "column", spacing: 2, sx: { width: "100%", height: "100%", overflowY: "auto" } }, { children: [_jsx(Stack, __assign({ direction: "row", justifyContent: "space-between", alignItems: "center", sx: { px: 2 } }, { children: _jsx(Typography, __assign({ sx: { mt: 2 }, level: "h2", component: "h1" }, { children: "Call Analytics" })) })), _jsx(Box, __assign({ sx: { mt: 1, px: 2, width: "100%", height: "100%" } }, { children: _jsxs(Box, __assign({ sx: { width: "100%", height: "100%", paddingBottom: 1 } }, { children: [_jsx(Card, __assign({ sx: { marginBottom: 1 } }, { children: _jsx(CardContent, { children: _jsxs(Box, __assign({ display: "flex", gap: 1 }, { children: [_jsxs(Select, __assign({ value: dateRange, onChange: function (e, newValue) {
                                                return setDateRange(newValue);
                                            }, placeholder: "Select Date Range", size: "sm", sx: { minWidth: 100 } }, { children: [_jsx(Option, __assign({ value: FilterEnums.last30Minutes }, { children: "Last 30 Minutes" })), _jsx(Option, __assign({ value: FilterEnums.last1Hour }, { children: "Last Hour" })), _jsx(Option, __assign({ value: FilterEnums.last4Hours }, { children: "Last 4 Hours" })), _jsx(Option, __assign({ value: FilterEnums.last12Hours }, { children: "Last 12 Hours" })), _jsx(Option, __assign({ value: FilterEnums.last24Hours }, { children: "Last 24 Hours" })), _jsx(Option, __assign({ value: FilterEnums.last48Hours }, { children: "Last 48 Hours" })), _jsx(Option, __assign({ value: FilterEnums.last3Days }, { children: "Last 3 Days" })), _jsx(Option, __assign({ value: FilterEnums.last7Days }, { children: "Last 7 Days" })), _jsx(Option, __assign({ value: FilterEnums.last1Month }, { children: "Last 1 Month" })), _jsx(Option, __assign({ value: FilterEnums.last3Months }, { children: "Last 3 Months" })), _jsx(Option, __assign({ value: FilterEnums.all }, { children: "All" }))] })), _jsxs(Select, __assign({ size: "sm", value: grouping, onChange: function (e, newValue) { return setGrouping(newValue); }, placeholder: "Select Grouping", sx: { minWidth: 100 } }, { children: [_jsx(Option, __assign({ value: "hourly" }, { children: "Hourly" })), _jsx(Option, __assign({ value: "daily" }, { children: "Daily" })), _jsx(Option, __assign({ value: "monthly" }, { children: "Monthly" }))] })), _jsxs(Select, __assign({ size: "sm", value: selectedFlow || "", onChange: function (e, newValue) {
                                                return setSelectedFlow(newValue || undefined);
                                            }, placeholder: "Select Flow", sx: { minWidth: 150 } }, { children: [_jsx(Option, __assign({ value: "" }, { children: "All Agents" })), flows.map(function (flow) { return (_jsx(Option, __assign({ value: flow.flowId }, { children: flow.flowName }), flow.flowId)); })] }))] })) }) })), _jsx(Card, { children: _jsx(CardContent, { children: isLoading ? (_jsx(Skeleton, { height: 400 })) : trendingChartData ? (_jsx(Box, __assign({ height: 400 }, { children: _jsx(Line, { data: {
                                            labels: adjustLabelsToLocalTime(trendingChartData.labels),
                                            datasets: trendingChartData.datasets.map(function (dataset) { return (__assign(__assign({}, dataset), { fill: false, tension: 0.4, borderWidth: 2, pointRadius: 4 })); }),
                                        }, options: {
                                            responsive: true,
                                            maintainAspectRatio: false,
                                            plugins: {
                                                legend: { display: true },
                                                tooltip: { mode: "index", intersect: false },
                                            },
                                            scales: {
                                                x: { display: true },
                                                y: { beginAtZero: true },
                                            },
                                        } }) }))) : (_jsx(Typography, { children: "No data available" })) }) }), _jsx(Card, __assign({ sx: { marginTop: 1 } }, { children: _jsxs(CardContent, { children: [_jsx(Typography, __assign({ level: "h3", sx: { mb: 2 } }, { children: "Call conversion" })), _jsxs(Box, __assign({ display: "flex", gap: 1, sx: { marginTop: 1 } }, { children: [_jsx(FormLabel, { children: "Lead locked after seconds" }), _jsx(Input, { value: conversionCriteriaInSeconds, type: "number", onChange: function (event) {
                                                    return setConversionCriteriaInSeconds(Number.parseInt(event.target.value));
                                                } })] })), isLoadingFunnel ? (_jsx(Skeleton, { height: 400 })) : funnelChartData && funnelChartData.length > 0 ? (_jsx(Box, __assign({ sx: {
                                            display: "grid",
                                            gap: 2,
                                            gridTemplateColumns: {
                                                xs: "1fr",
                                                sm: "1fr",
                                                md: "repeat(4, 1fr)", // medium and above: 4 columns
                                            },
                                        } }, { children: funnelChartData.map(function (item, index) {
                                            var localDateStr = new Date(item.timePeriod).toLocaleString(undefined, {
                                                month: "short",
                                                day: "numeric",
                                                year: "numeric",
                                                hour: grouping === "hourly" ? "numeric" : undefined,
                                                hour12: grouping === "hourly",
                                            });
                                            var funnelData = {
                                                labels: ["Calls", "Transferred", "Converted"],
                                                datasets: [
                                                    {
                                                        label: "Funnel: ".concat(localDateStr),
                                                        data: [
                                                            item.callCount,
                                                            item.transferredCount,
                                                            item.convertedCount,
                                                        ],
                                                        datalabels: {
                                                            formatter: function (v) { return v.toLocaleString(); },
                                                        },
                                                        backgroundColor: [
                                                            "rgba(255, 99, 132, 0.7)",
                                                            "rgba(54, 162, 235, 0.7)",
                                                            "rgba(75, 192, 75, 0.7)",
                                                        ],
                                                    },
                                                ],
                                            };
                                            var funnelOptions = {
                                                indexAxis: "y",
                                            };
                                            return (_jsxs(Box, __assign({ sx: { textAlign: "center" } }, { children: [_jsx(Typography, __assign({ level: "body-sm", sx: { mb: 1 } }, { children: localDateStr })), _jsx(Chart, { type: "funnel", data: funnelData, options: funnelOptions })] }), index));
                                        }) }))) : (_jsx(Typography, { children: "No call conversion data available" }))] }) }))] })) }))] })));
};
