var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import List from "@mui/joy/List";
import ChatListItem from "../ChatListItem";
import InfiniteScroll from "react-infinite-scroll-component"; // Import InfiniteScroll
export default function ChatsPane(props) {
    var chats = props.chats, setSelectedChat = props.setSelectedChat, selectedChatId = props.selectedChatId, loadMoreChats = props.loadMoreChats, hasMoreChats = props.hasMoreChats;
    return (_jsx(_Fragment, { children: _jsx(InfiniteScroll, __assign({ dataLength: chats.length, next: loadMoreChats, hasMore: hasMoreChats, loader: _jsx("h4", { children: "Loading..." }), scrollableTarget: "scrollableChatsPane" // Specify the scrollable container
            , style: { overflow: "unset" } }, { children: _jsx(List, __assign({ sx: {
                    py: 0,
                    "--ListItem-paddingY": "0.75rem",
                    "--ListItem-paddingX": "1rem",
                } }, { children: chats.map(function (chat) { return (_jsx(ChatListItem, __assign({}, chat, { unread: chat.unread, endDateTime: chat.endDateTime, duration: chat.duration, lastMessageText: chat.sender.lastMessageText, flowName: chat.sender.flowName, endReason: chat.sender.endReason, setSelectedChat: setSelectedChat, selectedChatId: selectedChatId }), chat.id)); }) })) })) }));
}
